module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Biz Logistics","short_name":"BIZ","start_url":"https://www.bizlogistics.nl/","background_color":"hsla(208, 24%, 30%, 1)","theme_color":"hsla(208, 24%, 30%, 1)","display":"standalone","icon":"icon/icon.png"},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
