// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("../node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-koerierdienst-js": () => import("../src/pages/koerierdienst.js" /* webpackChunkName: "component---src-pages-koerierdienst-js" */),
  "component---src-pages-offerte-afspraak-js": () => import("../src/pages/offerte-afspraak.js" /* webpackChunkName: "component---src-pages-offerte-afspraak-js" */),
  "component---src-pages-opslag-js": () => import("../src/pages/opslag.js" /* webpackChunkName: "component---src-pages-opslag-js" */),
  "component---src-pages-over-ons-js": () => import("../src/pages/over-ons.js" /* webpackChunkName: "component---src-pages-over-ons-js" */),
  "component---src-pages-spoed-opdrachten-js": () => import("../src/pages/spoed-opdrachten.js" /* webpackChunkName: "component---src-pages-spoed-opdrachten-js" */),
  "component---src-pages-succes-js": () => import("../src/pages/succes.js" /* webpackChunkName: "component---src-pages-succes-js" */),
  "component---src-pages-vacature-js": () => import("../src/pages/vacature.js" /* webpackChunkName: "component---src-pages-vacature-js" */)
}

